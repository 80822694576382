import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/cryptowander/Maker/test/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`MIP0c12: Subproposal Template for Core Personnel Onboarding`}</h1>
    <h2>{`Preamble`}</h2>
    <pre><code parentName="pre" {...{}}>{`MIP0c12-SP#: #
Author(s):
Contributors: 
Status:
Date Applied: <yyyy-mm-dd>
Date Ratified: <yyyy-mm-dd>
---
Core Personnel Role: MIP Editor or Governance Facilitator
Proposed applicant: Name of applicant
`}</code></pre>
    <h2>{`Application`}</h2>
    <h3>{`Motivation`}</h3>
    <ul>
      <li parentName="ul">{`Explanation of why and how you want to fulfil this role.
`}</li>
    </ul>
    <h3>{`Credentials`}</h3>
    <ul>
      <li parentName="ul">{`Past work experience.`}</li>
    </ul>
    <h3>{`Relevant Information`}</h3>
    <ul>
      <li parentName="ul">{`Github Account`}</li>
      <li parentName="ul">{`MakerDAO Forum Account `}</li>
      <li parentName="ul">{`Other relevant Accounts`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      